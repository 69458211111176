import React, {useRef} from 'react';
import Button from "../../shared/Button/Button";
import SignatureCanvas from 'react-signature-canvas'
import ReactSignatureCanvas from "react-signature-canvas";
import {useAppDispatch} from "../../utils/customHooks";
import {uploadSignFromBase64TC} from "../../store/visaPageReducer";

type PropsType = {
  signFiles: Array<string>
  index: number
}

const SignField = ({index, signFiles}: PropsType) => {
  const dispatch = useAppDispatch()
  const signCanvas = useRef<ReactSignatureCanvas | null>(null)
  const [isSignFieldActive, setIsSignFieldActive] = React.useState(!signFiles.length)

  const clear = () => {
    signCanvas.current?.clear()
  }

  const save = async () => {
    const isEmpty = signCanvas.current?.isEmpty()
    const base64 = signCanvas.current?.getTrimmedCanvas().toDataURL('image/png')
    if (!isEmpty && base64) {
       let res = await dispatch(uploadSignFromBase64TC({base64, index}))
       res.payload && setIsSignFieldActive(false)
    }
  }

  return (
    <div>
      <span
        className="nc-Label text-sm font-medium text-neutral-700 dark:text-neutral-300">
       {!isSignFieldActive ? 'Подпись' : 'Пожалуйста, оставьте свою подпись в поле ниже'}
      </span>
      {isSignFieldActive
        ? <div className="mt-5 ">
            <SignatureCanvas
              ref={signCanvas}
              canvasProps={{className: 'w-full h-[300px] border border-neutral-200 dark:border-neutral-700'}}/>
          <div className={'flex gap-2'}>
            <Button
              className="ttnc-ButtonSecondary font-medium border bg-white border-neutral-200 text-neutral-700 dark:bg-neutral-900 dark:text-neutral-300 dark:border-neutral-700 hover:bg-neutral-100 dark:hover:bg-neutral-800 mt-2"
              type="button"
              onClick={
                clear
              }>
              Очистить
            </Button>
            <Button
              className="ttnc-ButtonPrimary justify-self-end self-end disabled:bg-opacity-70 bg-primary-6000 hover:bg-primary-700 text-neutral-50 dark:text-neutral-200] md:self-center mt-2 "
              type="button"
              onClick={
                save
              }>
              Подтвердить
            </Button>
          </div>
        </div>
        : <div className="mt-5 ">
          <img src={signFiles[0]} alt="sign" className={'mx-auto w-2/4 h-auto border'}/>
          <Button
            className="ttnc-ButtonSecondary font-medium border bg-white border-neutral-200 text-neutral-700 dark:bg-neutral-900 dark:text-neutral-300 dark:border-neutral-700 hover:bg-neutral-100 dark:hover:bg-neutral-800 mt-2"
            type="button"
            onClick={
              () => setIsSignFieldActive(true)
            }>
            Заменить
          </Button>
        </div>
      }
    </div>
  );
};

export default SignField;