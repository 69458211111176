import React from 'react';
import {useAppDispatch, useAppSelector} from '../../../utils/customHooks';
import {useNavigate} from 'react-router-dom';
import {fileAddTC, filedCheckTC, hotelsDataChecker, updateApplicantField} from '../../../store/visaPageReducer';
import Button from '../../../shared/Button/Button';
import FileInput from '../../../shared/FileInput/FileInput';
import Input from '../../../shared/Input/Input';
import Label from '../../../components/Label/Label';
import FotoUploadModal from '../Modal/FotoUploadModal';
import SignField from "../../../components/SignField/SignField";


const VisaPage7Item = ({index, isPH}: { index: number,isPH?:boolean }) => {
    const {
        passportCopyFiles,
        photoFiles,
        inTicketsFiles,
        outTicketsFiles,
        bookingHotel,
        hotelsData,
        signFiles
    } = useAppSelector(state => state.visa_page.applicantsData[index])
    const service = useAppSelector(state => state.visa_page.service)
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const regExp = new RegExp(/с отел/)

  const addItem = () => {
    let editedObj = [...hotelsData, {
      hotelName: '',
      arrivalDate: '',
      departureDate: '',
      hotelAddress: '',
      bookingHotel: []
    }]
    dispatch(updateApplicantField({
      fieldName: 'hotelsData',
      value: editedObj,
      index
    }));
  }
  const delItem = (i: number) => {
    let editedObj = hotelsData.filter((el, eli) => eli !== i)
    dispatch(updateApplicantField({
      fieldName: 'hotelsData',
      value: editedObj,
      index
    }));
  }

  const onBlurDatesHandler = async (name: string, value: string, elIndex: number) => {

    let editedObj = hotelsData.map((el, index) => {
      return index === elIndex ? {...el, [name]: value} : el
    })
    dispatch(updateApplicantField({
      fieldName: 'hotelsData',
      value: editedObj,
      index
    }));
    return value
  }

  const onChangeHandler = async (fieldName: string, files: FileList | null, elementIndex: number) => {
    let arr: Array<string>
    if (files) {
      let res = await dispatch(fileAddTC({files}))
      arr = res.payload as Array<string>
      let editedObj = hotelsData.map((el, i) => {
        return i === elementIndex ? {...el, bookingHotel: arr} : el
      })
      dispatch(updateApplicantField({
        fieldName: 'hotelsData',
        value: editedObj,
        index
      }));
    }
  }

  const resetHandler = (fieldName: string, elementIndex: number) => {
    let editedObj = hotelsData.map((el, i) => {
      return i === elementIndex ? {...el, bookingHotel: []} : el
    })
    dispatch(updateApplicantField({
      fieldName: 'hotelsData',
      value: editedObj,
      index
    }));
  }

  const handleSubmit = async (e: any) => {
    e.preventDefault()
    let res
    if (regExp.test(service)) {
      res = await dispatch(hotelsDataChecker())
      if (!res.payload) {
        return
      }
      res = await dispatch(filedCheckTC({
        passportCopyFiles,
        photoFiles,
        inTicketsFiles,
        outTicketsFiles,
        signFiles
      }))
    } else {
      res = await dispatch(filedCheckTC({
        passportCopyFiles,
        photoFiles,
        inTicketsFiles,
        outTicketsFiles,
        bookingHotel,
        signFiles
      }))
    }
    if (res.payload) {
      navigate('/visa8')
    }
  }

  return (
    <form className="mt-6 mb-4" onSubmit={handleSubmit}>
      <FotoUploadModal initialState={!photoFiles.length}/>
      <div className="space-y-8">

        <FileInput fieldName={'passportCopyFiles'}
                   title={'Копия паспорта (со сроком окончания действия не менее 6 месяцев)'} index={index}/>

        <FileInput fieldName={'photoFiles'} title={'Ваша фотография студийного качества'} index={index}
                   types={'image/png, image/jpeg, image/jpg'} typesName={'PNG, JPG, JPEG'}/>

        {regExp.test(service)
          ? <>{hotelsData.map((el, elementIndex) => {
            return (
              <div key={elementIndex}>
                <div className="flex flex-col sm:grid gap-3 mt-4 grid-rows-3 grid-flow-col">
                  <div className={'col-span-2'}>
                    <Label className="text-center">Название отеля</Label>
                    <Input
                      className="mt-2"
                      type="text"
                      placeholder={'Укажите название отеля'}
                      required
                      value={el.hotelName}
                      onChange={(e) => {
                        if (/[А-яЁё]/i.test(e.currentTarget.value)) {
                          return
                        }
                        onBlurDatesHandler('hotelName', e.currentTarget.value, elementIndex)
                      }}
                    />
                  </div>
                  <div className={'col-span-2'}>
                    <Label className="text-center">Полный адрес отеля</Label>
                    <Input
                      className="mt-2"
                      type="text"
                      placeholder={'Укажите полный адрес отеля'}
                      required
                      value={el.hotelAddress}
                      onChange={(e) => {
                        if (/[А-яЁё]/i.test(e.currentTarget.value)) {
                          return
                        }
                        onBlurDatesHandler('hotelAddress', e.currentTarget.value, elementIndex)
                      }}
                    />
                  </div>
                  <div>
                    <Label className="text-center">Дата заселения</Label>
                    <Input
                      className="mt-2"
                      type="date"
                      required
                      value={el.arrivalDate}
                      onChange={(e) => {
                        onBlurDatesHandler('arrivalDate', e.currentTarget.value, elementIndex)
                      }}
                    />

                  </div>
                  <div>
                    <Label className="text-center">Дата выселения</Label>
                    <Input
                      className="mt-2"
                      type="date"
                      required
                      value={el.departureDate}
                      onChange={(e) => {
                        onBlurDatesHandler('departureDate', e.currentTarget.value, elementIndex)
                      }}
                    />

                  </div>
                  <div className={'sm:row-span-2 sm:self-center sm:mt-5'}>
                    {elementIndex !== 0 && <div onClick={() => {
                      delItem(elementIndex)
                    }}
                                                className={'self-end p-2 mt-2 sm:w-[40px] flex-shrink-0 text-center mx-auto cursor-pointer rounded-full bg-red-500'}>-</div>}
                    <div onClick={() => {
                      addItem()
                    }}
                         className={'self-end p-2 mt-24 sm:w-[40px] flex-shrink-0 text-center mx-auto cursor-pointer rounded-full bg-neutral-200 dark:bg-gray-700'}>+
                    </div>
                  </div>
                </div>
                <div className="mt-2">
                  <FileInput fieldName={'hotelsData'}
                             innerIndex={elementIndex}
                             innerName={'bookingHotel'}
                             callback={onChangeHandler}
                             resetCallBack={resetHandler}
                             title={'Загрузите скриншот выбранного вами отеля'}
                             index={index}/>
                </div>
              </div>
            )
          })}</>
          :
          <FileInput fieldName={'bookingHotel'} title={'Бронирование выбранного вами отеля'} index={index}/>}
        {/*<FileInput fieldName={'bookingHotel'} title={'Бронирование отеля или скрин выбранного вами отеля'} index={index}/>*/}

        <FileInput fieldName={'inTicketsFiles'} multiple={true} title={'Билеты/круиз в Сингапур'}
                   index={index}/>

        <FileInput fieldName={'outTicketsFiles'} multiple={true} title={'Билеты/круиз из Сингапура'}
                   index={index}/>

        <FileInput fieldName={'workPlaceFiles'} multiple={true} title={'Справка с места работы (не обязательно)'}
                   index={index}/>

        <FileInput fieldName={'additionFiles'} multiple={true} title={'Дополнительные документы'}
                   index={index}/>

        <SignField index={index} signFiles={signFiles}/>
      </div>
      <div className="flex justify-end space-x-5 mt-8">
        <Button
          className={`ttnc-ButtonSecondary font-medium border bg-white border-neutral-200 text-neutral-700 dark:bg-neutral-900 dark:text-neutral-300 dark:border-neutral-700 hover:bg-neutral-100 dark:hover:bg-neutral-800`}
          href={'/visa6'}>Назад</Button>
        <Button
          className={`ttnc-ButtonPrimary disabled:bg-opacity-70 bg-primary-6000 hover:bg-primary-700 text-neutral-50 ${isPH ? 'bg-[#4f46e5] hover:bg-[#4f46e5]/95' : ''} dark:text-neutral-200`}
          type={'submit'}
        >Продожить
        </Button>
      </div>
    </form>
  )
    ;
};

export default VisaPage7Item;